import { Box, Stack, Text } from '@chakra-ui/layout'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useFormContext } from 'react-hook-form'

import CenterSpinner from '../../../../components/CenterSpinner'
import DrawButton from '../../../../components/DrawButton'
import FullpageHeight from '../../../../components/FullpageHeight'
import withFirestoreData from '../../../../utils/withFirestoreData'

const Invitation = ({ invitationId, firestoreData: { data, status } }) => {
  const { setValue } = useFormContext()
  useEffect(() => {
    setValue('invitationId', invitationId)
  }, [invitationId])

  useEffect(() => {
    if (data?.inviteeEmail) {
      setValue('email', data?.inviteeEmail)
    }
  }, [data?.inviteeEmail])
  if (status === 'loading') return <CenterSpinner />
  return (
    <FullpageHeight maxWidth="container.sm" py="8" as={Stack} px="4" alignItems="center">
      <Helmet>
        <title>接受邀請 - 愛之語測驗愛情版</title>
      </Helmet>
      <StaticImage src="../love.png" alt="愛之語愛情版" />
      <Text fontWeight="700" lineHeight="1.75" color="love.text">
        這是來自{data?.name}的邀請，他想知道你們之間的「愛之語分析」完成填答後，你將看到「自己的愛之語分析」，接著你可以選擇要不要跟對方分享，並收到雙人進階分析報告！若選擇跟對方分享，兩人都會收到一份「雙人分析＆相處建議」
      </Text>
      <Box flex="1" />
      <DrawButton isLoading={status === 'loading'} state={{ email: data?.inviteeEmail }} to="/q/love-languages/lover/dual/">開始測驗</DrawButton>
    </FullpageHeight>
  )
}

const EnhancedAccpt = withFirestoreData(Invitation)

const HandleFiresoreData = (props) => {
  const invitationId = useMemo(() => {
    const params = new URLSearchParams(props.location.search)
    return params.get('key')
  }, [props.location.search])
  return <EnhancedAccpt firestorePath={`invitations/${invitationId}`} invitationId={invitationId} {...props} />
}

export default HandleFiresoreData
